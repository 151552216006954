.messages-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.messages-container::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
    background: var(--theme-1);
    border-radius: 2px;
    cursor: pointer;
}

.message {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.message:hover, .message-hovered {
    background-color: #1c1c1c20;
}

.message-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    margin: 3px 10px;
    gap: 7px;
}

.message-bigger-margin {
    margin-top: 10px;
    align-items: start;
}

.message-info-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.message-info {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.message-username {
    font-weight: bold;
    color: var(--theme-text-main);
}

.message-timestamp {
    color: var(--theme-7);
    font-size: 13px;
}

.message-content {
    color: var(--theme-text-main);
    font-size: 14px;
    line-height: 24px;
    white-space: pre-wrap;
}

.message-content-pending {
    color: var(--theme-6);
}

.message-timestamp-left, .message-icon-left {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--theme-7);
    visibility: hidden;
    font-size: 12px;
    width: 56px;
    flex-shrink: 0;
}

.message-icon-left {
    visibility: visible;
}

.message:hover > .message-container > .message-timestamp-left {
    visibility: visible;
}

.btn-ping {
    padding: 2px 3px!important;
    border-radius: 4px!important;
    font-size: 14px!important;
    font-weight: bold;
}

.message-container-mention {
    background-color: #ffe20020;
}

.message-component-link {
    color: #74a7ff;
}

.message-component-link:visited {
    color: #1e70ff;
}

.message-menu {
    display: flex;
    background-color: var(--theme-2);
    flex-direction: row;
    gap: 5px;
    border-radius: 5px;
    height: 32px;
    color: var(--theme-text-primary);
    align-items: center;
    padding: 3px 5px;
}

.message-menu-button {
    background-color: transparent;
    border-radius: 3px;
}

.message-menu-button:hover {
    background-color: #ffffff20;
}

.message-menu-button:active {
    background-color: #ffffff30;
}

.message-spoiler {
    background-color: var(--theme-1);
    cursor: pointer;
    border-radius: 3px;
}

.message-spoiler-show, .message-time {
    background-color: var(--theme-4);
    border-radius: 3px;
}

.message-spoiler > .spoiler-content {
    visibility: hidden;
}

.edit-input {
    border-radius: 7px;
    background-color: var(--theme-1);
    padding: 10px;
    width: 100%;
}