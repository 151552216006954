.badge {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.online-badge {
    background-color: #24be24;
}

.offline-badge {
    background-color: var(--theme-3);
    border: 3px solid var(--theme-6);
}

.idle-badge {
    background-color: #ffac2e;
}

.dnd-badge {
    background-color: #ff4f4f;
}