.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-0);
    height: 100%;
    width: 100%;
}

.login-form-container {
    display: flex;
    flex-direction: row;
    width: 60%;
    border-radius: 15px;
    background-color: var(--theme-2);
    padding: 25px;
}

.login-form {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5px;
    color: var(--theme-text-secondary);
}

.login-form-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--theme-text-primary);
    gap: 10px;
}

.login-form-header > * {
    margin-block-end: 0;
    margin-block-start: 0;
}

.input-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--theme-text-primary);
}

.required-asterisk {
    color: red;
}

.form-link {
    text-decoration: none;
    color: #156bff!important;
}

.form-link:hover {
    text-decoration: underline;
}

.login-form > *, .register-form > * {
    margin-block-end: 0;
    margin-block-start: 0;
}

.remote-auth-form {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
}

.remote-auth-form > * {
    margin-block-end: 0;
    margin-block-start: 0;
    color: var(--theme-text-primary);

    text-align: center;
}

.remote-auth-qr {
    width: 65%;
    height: auto;
    padding: 15px;
    background-color: white;
    border-radius: 15px;
}

.remote-auth-avatar {
    width: 50%;
    height: auto;
    border-radius: 50%;
}

.register-form-container, .mfa-form-container {
    display: flex;
    flex-direction: row;
    width: 40%;
    border-radius: 15px;
    background-color: var(--theme-2);
    padding: 25px;
}

.mfa-form-container {
    width: 35%!important;
}

.register-form, .mfa-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5px;
    color: var(--theme-text-secondary);
}

.register-form > h2 {
    text-align: center;
    margin-block-end: 0;
    margin-block-start: 0;
}

.date-of-birth-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.date-of-birth-container > select {
    flex-grow: 1;
    color: var(--theme-text-secondary);
}