.friends-list-container {
    margin: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
}

.friends-list-container > p {
    color: var(--theme-text-secondary);
    font-size: 12px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.friends-list {
    flex-grow: 1;
    overflow-y: scroll;
}

.friends-list::-webkit-scrollbar {
    display: none;
}

.friends-list > .profile-panel > .profile-panel-buttons {
    gap: 10px;
}

.friends-list > .profile-panel > .profile-panel-buttons > svg {
    background-color: #2f2f2f;
    height: 36px;
    width: 36px;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
}

.friends-list > .profile-panel > .profile-panel-buttons > svg:hover {
    background-color: var(--theme-0);

}

.friends-list > .profile-panel:hover {
    background-color: var(--theme-2);
    transition: all .15s ease-in;
    border-radius: 15px;
    cursor: pointer;
}

.friend-discriminator {
    display: none;
}

.friend-username {
    display: flex;
    flex-direction: row;
}

.friends-list > .profile-panel:hover .friend-discriminator {
    display: block;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--theme-6);
    height: 100%;
}

.channel-beginning-container {
    display: flex;
    flex-direction: column;
    color: var(--theme-text-main);
    margin: 20px;
}

.channel-beginning-container > h1 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.channel-beginning-container > .dm-channel-icon {
    margin-left: 0;
}

.channel-beginning-text {
    color: var(--theme-6);
}

.channel-beginning-username-text {
    color: var(--theme-text-secondary);
    font-weight: bold;
}

.channel-beginning-icon {
    border-radius: 50%;
    background-color: var(--theme-4);
    padding: 10px;
    width: 80px!important;
    height: 80px!important;
}