body {
    --theme-0: #1c1c1c;
    --theme-1: #2d2d2d;
    --theme-1p5: #333333;
    --theme-2: #3b3b3b;
    --theme-3: #4b4b4b;
    --theme-4: #646464;
    --theme-5: #737373;
    --theme-6: #888888;
    --theme-7: #9d9d9d;
    --theme-8: #b4b4b4;
    
    --theme-text-secondary: #c5c5c5;
    --theme-text-primary: #eaeaea;
    --theme-text-main: white;

    --theme-c-primary: #156bff;
    --theme-c-primary-h: #0261ff;
    --theme-c-primary-a: #004ed7;
    --theme-c-primary-d: #003793;
    
    --theme-c-secondary: var(--theme-3);
    --theme-c-secondary-h: var(--theme-4);
    --theme-c-secondary-a: var(--theme-5);
    --theme-c-secondary-d: var(--theme-8);
    
    --theme-c-danger: #ff3b3b;
    --theme-c-danger-h: #ff0000;
    --theme-c-danger-a: #930000;
    --theme-c-danger-d: #830000;
    
    --theme-c-success: #00ab0d;
    --theme-c-success-h: #00cb0d;
    --theme-c-success-a: #00860a;
    --theme-c-success-d: #006208;
}

.input-primary {
    background-color: var(--theme-0);
    color: var(--theme-text-primary);
    height: 35px;
    border-radius: 7px;
    font-size: 16px;
    border: none;
    padding-left: 10px;
}

.w-100 {
    width: 100%;
}

.bg-transparent {
    background-color: transparent!important;
}

.space-between {
    display: flex;
    justify-content: space-between!important;
}

.align-center {
    display: flex;
    align-items: center!important;
}

.justify-center {
    display: flex;
    justify-content: center!important;
}

.margin-tb-10px {
    margin: 0 10px;
}

.d-none {
    display: none!important;
}

.vis-hidden {
    visibility: hidden;
}

.h-0 {
    height: 0;
}

.font-13 {
    font-size: 13px;
}

.btn-icon {
    color: var(--theme-5);
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.btn {
    display: flex;
    flex-direction: row;
    padding: 7px 10px;
    border: none;
    border-radius: 7px;
    color: var(--theme-text-main);
    transition: all .1s ease-out;
    font-size: 16px;
    align-items: center;
    align-self: flex-start;
}

.btn-wide {
    height: 40px;
    width: 100%;
}

.btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-primary {
    color: var(--theme-text-main);
    background-color: var(--theme-c-primary);
}

.btn-primary:hover {
    background-color: var(--theme-c-primary-h)!important;
}

.btn-primary:active {
    background-color: var(--theme-c-primary-a)!important;
}

.btn-primary:disabled {
    background-color: var(--theme-c-primary-d)!important;
}

.btn-success {
    color: var(--theme-text-main);
    background-color: var(--theme-c-success);
}

.btn-success:hover {
    background-color: var(--theme-c-success-h);
}

.btn-success:active {
    background-color: var(--theme-c-success-a);
}

.btn-success:disabled {
    background-color: var(--theme-c-success-d);
}

.btn-danger {
    color: var(--theme-text-main);
    background-color: var(--theme-c-danger);
}

.btn-danger:hover {
    background-color: var(--theme-c-danger-h);
    border: none;
    box-shadow: none;
}

.btn-danger:active {
    background-color: var(--theme-c-danger-a);
    border: none;
    box-shadow: none;
}

.btn-danger-outline {
    box-shadow: inset 0 0 0 2px var(--theme-c-danger);
    background-color: transparent;
}

.btn-secondary {
    color: var(--theme-text-main);
    background-color: var(--theme-c-secondary);
}

.btn-secondary:hover {
    background-color: var(--theme-c-secondary-h);
}

.btn-secondary:active {
    background-color: var(--theme-c-secondary-a);
}

.btn-secondary-transparent {
    color: var(--theme-8);
    background-color: transparent;
}

.btn-secondary-transparent:hover {
    background-color: var(--theme-c-secondary-h);
}

.btn-secondary-transparent:active, .btn-secondary-transparent-selected {
    background-color: var(--theme-c-secondary-a);
    color: var(--theme-text-main);
}

.btn-transparent {
    color: var(--theme-text-main);
    background-color: transparent;
}

.btn-primary-transparent:hover {
    background-color: var(--theme-c-primary-h)!important;
}

.btn-primary-transparent:active, .btn-primary-transparent-selected {
    background-color: var(--theme-c-primary-a)!important;
    color: var(--theme-text-main);
}

.btn-danger-transparent:hover {
    background-color: var(--theme-c-danger-h)!important;
}

.btn-danger-transparent:active, .btn-danger-transparent-selected {
    background-color: var(--theme-c-danger-a)!important;
    color: var(--theme-text-main);
}

.btn-success-transparent:hover {
    background-color: var(--theme-c-success-h)!important;
}

.btn-success-transparent:active, .btn-success-transparent-selected {
    background-color: var(--theme-c-success-a)!important;
    color: var(--theme-text-main);
}

.btn-color-primary {
    color: var(--theme-c-primary)!important;
}

.btn-color-primary:hover {
    background-color: var(--theme-c-primary-h)!important;
    color: var(--theme-text-main)!important;
}

.btn-color-primary:active {
    background-color: var(--theme-c-primary-a);
    color: var(--theme-text-main);
}

.btn-color-danger {
    color: var(--theme-c-danger-h)!important;
}

.btn-color-danger:hover {
    background-color: var(--theme-c-danger)!important;
    color: var(--theme-text-main)!important;
}

.btn-color-danger:active {
    background-color: var(--theme-c-danger-a);
    color: var(--theme-text-main);
}

.user-banner {
    width: 100%;
    background-color: var(--theme-3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.text-primary {
    color: var(--theme-text-primary);
}

.text-secondary {
    color: var(--theme-text-secondary);
}

.text-main {
    color: var(--theme-text-main);
}

.text-14 {
    font-size: 14px;
}

.text-bold {
    font-weight: bold;
}

.switch-checkbox {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;
}

.switch-checkbox > input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-checkbox-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--theme-5);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 20px;
}

.switch-checkbox-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: var(--theme-text-main);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .switch-checkbox-slider {
    background-color: var(--theme-c-success-h);
}

input:checked + .switch-checkbox-slider:before {
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.display-row {
    display: flex;
    flex-direction: row;
}

.gap-10 {
    display: flex;
    gap: 10px;
}

.selectable {
    user-select: text;
}

.margin-0 {
    margin: 0!important;
}

.input-color {
    width: 70px;
    height: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
}

.input-color::-webkit-color-swatch-wrapper {
    padding: 0;
}

.input-color::-webkit-color-swatch {
    border: none;
    border-radius: 10px;
    background-color: transparent;
}

.input-slider {
    cursor: col-resize;
}

.input-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 10px;
    height: 17px;
    background-color: var(--theme-7);
}