body, html, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  user-select: none;
}

.main-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

button {
  cursor: pointer;
}