.profile-dialog-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-dialog {
    width: 600px;
}

.profile-dialog-banner {
    width: 100%;
    height: 100px;
    background-color: var(--theme-3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.profile-dialog-content {
    background-color: var(--theme-1);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    flex-direction: column;
}

.profile-dialog-badges-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
    color: var(--theme-6);
}

.profile-dialog-avatar > .dm-channel-icon, .profile-dialog-badges-btns > .dm-channel-icon {
    margin: 0;
}

.profile-dialog-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

.profile-dialog-info-card {
    margin: 10px;
    color: var(--theme-text-main);
    background-color: var(--theme-0);
    border-radius: 10px;
    padding: 15px;
}