.channel-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--theme-3);
}

.channel-titlebar {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 15px;
    color: var(--theme-text-primary);
    justify-content: space-between;
}

.channel-titlebar-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.channel-titlebar > svg {
    fill: var(--theme-text-primary);
}

.titlebar-icon-button {
    fill: var(--theme-text-primary);
    cursor: pointer;
}

.titlebar-icon-button:hover {
    fill: var(--theme-text-primary);
    transition: all .1s ease-in;
}

.channel-content {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.vertical-divider {
    width: 2px;
    height: 75%;
    background-color: var(--theme-5);
    margin: 5px 10px;
    border-radius: 2px;
}

.friends-buttons-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.friends-buttons {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 10px;
}

.titlebar-friends-button-green {
    color: var(--theme-text-primary);
    padding: 7px 10px;
    background-color: #1c911c;
    border: none;
    font-size: 16px;
    border-radius: 7px;
}

.titlebar-friends-button-green:hover {
    color: #24be24;
    background-color: #24be2440;
}

.titlebar-friends-button-green-selected {
    color: #24be24;
    background-color: transparent;
}

.channel-titlebar-title {
    font-weight: bold;
}

.channel-messages {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
    overflow-y: scroll;
    height: 0;
    max-width: 100%;
}

.channel-messages::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.channel-messages::-webkit-scrollbar-thumb {
    background: var(--theme-1);
    border-radius: 2px;
    cursor: pointer;
}

.channel-input-container-1 {
    background-color: var(--theme-4);
    border-radius: 10px;
    margin: 10px 15px;
}

.channel-input-container-2 {
    display: flex;
    flex-direction: row;
    margin: 5px;
    gap: 10px;
}

.channel-input {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    color: var(--theme-text-main);
    font-size: 15px;
    outline: none;
    resize: none;
}

.channel-input::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.channel-input::-webkit-scrollbar-thumb {
    background: #2f2f2f;
    border-radius: 2px;
    cursor: pointer;
}

.channel-input-icon {
    color: var(--theme-text-secondary);
    cursor: pointer;
    height: 28px!important;
    width: 28px!important;
}

.add-friend-form {
    margin: 20px;
}

.add-friend-header {
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--theme-text-main);
}

.add-friend-input {
    display: flex;
    flex-direction: row;
    background-color: var(--theme-0);
    padding: 7px;
    border-radius: 10px;
    gap: 5px;
}

.add-friend-input > input {
    flex-grow: 1;
    outline: none;
}