.loader-root {
    background-color: var(--theme-3);
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.loader-navbar {
    height: 100%;
    width: 75px;
    background-color: var(--theme-1);
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.loader-navbar::-webkit-scrollbar {
    display: none;
}

.loader-panel-container {
    display: flex;
    flex-direction: column;
}

.loader-panel {
    flex-grow: 1;
    width: 250px;
    padding: 10px 10px;
    background-color: var(--theme-2);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loader-dm-channel {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: 50px;
}

.loader-channel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
}

.loader-channel-titlebar {
    display: flex;
    flex-direction: row;
    height: 50px;
    color: var(--theme-6);
}

.loader-channel-titlebar-content {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    flex-grow: 1;
    align-items: center;
}

.loader-channel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--theme-6);
    font-weight: bold;
    font-size: 24px;
    flex-grow: 1;
    width: 100%;
    gap: 10px;
}

.loader-message-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 15px 0;
}

.loader-message-avatar {
    margin: 0 10px;
}

.loader-message-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.loader-message-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px;
}