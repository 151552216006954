.settings-dialog {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: var(--theme-6);
}

.settings-categories-container {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: flex-end;
    background-color: var(--theme-1);;
    flex-grow: 1;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 30px;
}

.settings-content-container {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: flex-start;
    background-color: var(--theme-3);
    flex-grow: 1;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
}

.settings-categories-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 10px;
}

.settings-categories-list::-webkit-scrollbar {
    display: none;
}

.settings-close-button {
    padding: 6px;
    height: 40px!important;
    width: 40px!important;
    border-radius: 50%;
    border: 2px solid var(--theme-4);
    transition: all .1s ease;
}

.settings-close-button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 10px;
    cursor: pointer;
}

.settings-close-button-container:hover > div {
    color: var(--theme-8);
    font-weight: bold;
    transition: all .1s ease;
}

.settings-close-button-container:hover > .settings-close-button {
    border: 3px solid var(--theme-8);
    fill: var(--theme-8);
}

.settings-content {
    display: flex;
    gap: 10px;
    max-width: 70%;
    flex-grow: 1;
    flex-direction: column;
    color: var(--theme-text-main);
    overflow-y: scroll;
}

.settings-content::-webkit-scrollbar {
    display: none;
}

.settings-content > h3 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.settings-card {
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.settings-card-head {
    width: 100%;
    height: 100px;
    background-color: var(--theme-2);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.settings-card-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--theme-0);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px;
}

.settings-card-profile-info {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    min-height: 50px;
    margin: 10px 0;
}

.card-profile-fusername {
    display: flex;
    flex-direction: row;
}

.card-profile-fusername > h3 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.card-profile-username {
    color: var(--theme-text-main);
}

.card-profile-discriminator {
    color: var(--theme-6);
}

.button-end {
    margin-left: auto;
}

.card-secondary {
    background-color: #2f2f2f;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    font-size: 14px;
    gap: 20px;
}

.card-info-row {
    display: flex;
    flex-direction: row;
    margin: 0 5px;
    gap: 15px;
}

.card-info-text {
    display: flex;
    flex-direction: column;
}

.card-text-secondary {
    color: var(--theme-8);
}

.privacy-radio-container {
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    color: var(--theme-text-secondary);
    align-items: center;
    background-color: var(--theme-1p5);
    padding: 7px;
    cursor: pointer;
}

.privacy-radio-container-selected, .privacy-radio-container:hover {
    color: var(--theme-text-main);
    background-color: var(--theme-2);
}

.privacy-radio-container-green {
    border-left: 3px solid #24be24;
}

.privacy-radio-container-orange {
    border-left: 3px solid #ff7300;
}
.privacy-radio-container-red {
    border-left: 3px solid #ff0000;
}

.privacy-radio-input {
    margin: 7px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.privacy-option-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.privacy-option-text-title {
    font-size: 16px;
}

.privacy-option-text-desc {
    font-size: 14px;
}

.checkbox-option-container {
    display: flex;
    flex-direction: column;
}

.checkbox-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.edit-settings-dialog {
    width: 450px;
    background-color: var(--theme-1);
    border-radius: 7px;
    padding: 15px;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.edit-settings-dialog > .center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-settings-dialog > .center > h2 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.edit-settings-dialog-bottom-buttons {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.mfa-enable-step {
    margin: 0 15px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.mfa-enable-step-img {
    width: 96px;
    height: 96px;
}

.mfa-enable-step-qr {
    width: 96px;
    height: 96px;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
}

.mfa-enable-step-qr > * {
    width: 100%;
    height: 100%;
}

.mfa-enable-step-text {
    display: flex;
    flex-direction: column;
}

.backup-codes-columns {
    display: flex;
    flex-direction: row;
    gap: 75px;
    font-size: 16px;
}

.backup-codes-rows {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.backup-code-used  {
    display: flex;
    flex-direction: row;
    text-decoration: line-through;
    color: var(--theme-text-secondary);
}

.settings-profiles-user {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.settings-profiles-user-col {
    display: flex;
    flex-direction: column;
    width: 350px;
}

.image-upload-dialog {
    width: 500px;
    display: flex;
    flex-direction: column;
    background-color: var(--theme-4);
    padding: 10px;
}

.image-upload-crop-container {
    width: 100%;
    height: 300px;
    margin: 10px 0;
}