.servers-list {
    height: 100%;
    width: 75px;
    background-color: var(--theme-1);
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    flex-shrink: 0;
}

.servers-list::-webkit-scrollbar {
    display: none;
}

.server-icon {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    cursor: pointer;
}

.server-icon > img {
    max-width: 100%;
    border-radius: 50%;
}

.server-icon-selected > img, .server-icon > img:hover {
    border-radius: 25%;
}

.server-icon-selected {
    position: relative;
}

.server-icon-selected:after {
    content: "";
    background-color: var(--theme-text-main);
    position: absolute;
    left: 25%;
    bottom: -10%;
    height: 3px;
    width: 50%;
    border-radius: 2px;
}

.icons-divider {
    border-bottom-width: 2px;
    border-radius: 2px;
    width: 36px;
    background-color: var(--theme-6);
}

.server-icon-button {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: var(--theme-4);
    cursor: pointer;
}

.server-icon-button-wr {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.server-icon-button > .server-icon-button-wr > svg {
    fill: #24be24;
    height: 100%;
    width: 100%;
    padding: 20%;
}

.server-icon-button:hover {
    border-radius: 25%;
    background-color: #24be24;
}

.server-icon-button > .server-icon-button-wr > svg:hover {
    fill: var(--theme-text-main);
}

.server-icon-selected > img, .server-icon > img:hover, .server-icon-button:hover, .server-icon-button > .server-icon-button-wr > svg:hover {
    transition: all .1s ease-out;
}

.add-guild-dialog {
    width: 450px;
    background-color: var(--theme-2);
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    gap: 10px;
}

.add-guild-dialog > .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.add-guild-dialog > .center > h2 {
    color: var(--theme-text-main);
    margin-block-start: 0;
    margin-block-end: 0;
}