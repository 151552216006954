.channel-panel {
    height: 100%;
    width: 250px;
    background-color: var(--theme-2);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.find-or-start-conversation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.find-or-start-conversation {
    margin: 15px;
    width: 100%;
    height: 24px;
    background-color: var(--theme-1);
    cursor: pointer;
    padding-left: 5px;
    font-size: 12px;
    color: var(--theme-text-secondary);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.dm-btn {
    margin: 7px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.dm-btn-icon {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.dm-btn-icon > svg {
    width: 28px;
    height: 28px;
    fill: var(--theme-7);
}

.dm-btn-text {
    margin-left: 5px;
    color: var(--theme-7);
}

.dm-btn:hover {
    background-color: var(--theme-4);
    border-radius: 7px;
}

.dm-btn:hover > .dm-btn-icon > svg, .dm-btn:hover > .dm-btn-text {
    color: var(--theme-8);
    fill: var(--theme-8);
}

.dm-divider {
    display: flex;
    height: 20px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    align-items: center;
    margin-bottom: 5px;
}

.dm-divider-text {
    color: var(--theme-7);
}

.dm-divider-icon > svg {
    fill: var(--theme-7);
    cursor: pointer;
}

.dm-channel-item {
    margin-left: 7px;
    margin-right: 7px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
}

.dm-channel-icon {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.dm-channel-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dm-channel-item > svg {
    display: none;
    margin-right: 10px;
}

.dm-channel-item:hover > svg {
    display: block;
    fill: var(--theme-6);
}

.dm-channel-item:hover > svg:hover {
    fill: var(--theme-8);
    cursor: pointer;
}

.dm-channel-icon-img {
    border-radius: 50%;
}

.dm-channel-item:hover {
    background-color: var(--theme-3);
    border-radius: 7px;
}

.dm-channel-name {
    margin-left: 5px;
    color:var(--theme-7);
}

.dm-channel-list {
    height: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: scroll;
    gap: 10px;
}

.dm-channel-list::-webkit-scrollbar {
    display: none;
}

.profile-panel {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--theme-1p5);
}

.profile-panel-user {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-panel-username {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.profile-panel-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-right: 10px;
}

.profile-panel-buttons > * {
    fill: var(--theme-8) !important;
    cursor: pointer;
}

.channel-guild-title {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--theme-text-main);
    padding: 0 10px;
    cursor: pointer;
    transition: all .2s ease;
    font-weight: bold;
}

.channel-guild-title:hover {
    background-color: var(--theme-3);
}

.guild-channel-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 15px 0;
}

.category-channel {
    display: flex;
    margin: 0 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--theme-text-secondary);
    transition: all .1s ease;
}

.category-channel > span {
    display: flex;
    align-items: center;
}

.category-channel:hover {
    color: var(--theme-text-main);
}

.channel-panel-item-container {
    display: flex;
    flex-direction: row;
}

.channel-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1
}

.channel-panel-item-name {
    display: flex;
    align-items: center;
    gap: 5px;
}

.channel-panel-guild-items {
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding-left: 15px;
}

.channel-create-dialog {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
    background-color: var(--theme-1);
}

.channel-create-dialog-title > * {
    margin-block-start: 0;
    margin-block-end: 0;
}